<template>
  <v-container>
    <v-row>
      <v-col cols="10">
        <h4 class="text-h5">
          Nowa faktura
        </h4>
      </v-col>
      <v-col cols="2">
        <v-btn color="primary">Zapisz</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title>
            POL-BUD
            <v-spacer></v-spacer>
            <v-btn
              outlined
              small
              color="primary"
              class="ma-2"
            >
              <v-icon small>
                {{ icons.mdiClose }}
              </v-icon>
              Usuń zaznaczenie
            </v-btn>
            <v-btn
              small
              color="primary"
            >
              <v-icon small>
                {{ icons.mdiPencil }}
              </v-icon>
              Edytuj
            </v-btn>
          </v-card-title>
          <v-card-subtitle>
            NIP: 123456789
          </v-card-subtitle>
          <v-card-text>
            <p style="margin: 0 0 0 0">Kowalska 12</p>
            <p style="margin: 0 0 0 0">00-001 Warszawa</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <h4 class="text-h5">
          Dane faktury
        </h4>
        <v-row>
          <v-col cols="6">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="hw.issueDate"
                  label="Data zakupu"
                  outlined
                  dense
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="hw.issueDate"
                locale="pl"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="hw.issueDate"
                  label="Data wystawienia"
                  outlined
                  dense
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="hw.issueDate"
                locale="pl"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            TEST
          </v-col>
          <v-col cols="6">
            TEST
          </v-col>
        </v-row>
<!--        <v-row>-->

<!--        </v-row>-->
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <h4 class="text-h5">
          Załączniki
        </h4>
        <v-card>
          <v-list
            subheader
            two-line
          >
            <v-subheader inset>Folders</v-subheader>

            <v-list-item
              v-for="folder in folders"
              :key="folder.title"
            >
              <v-list-item-avatar>
                <v-icon
                  class="grey lighten-1"
                  dark
                >
                  mdi-folder
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="folder.title"></v-list-item-title>

                <v-list-item-subtitle v-text="folder.subtitle"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-subheader inset>Files</v-subheader>

            <v-list-item
              v-for="file in files"
              :key="file.title"
            >
              <v-list-item-avatar>
                <v-icon
                  :class="file.color"
                  dark
                  v-text="file.icon"
                ></v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="file.title"></v-list-item-title>

                <v-list-item-subtitle v-text="file.subtitle"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-information</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h4 class="text-h5">
          Pozycje faktury
        </h4>
        <v-card>
          <v-card-text>
            <table class="responsive-table">
              <thead>
              <tr>
                <th>No</th>
                <th>Item</th>
                <th>Price/unite</th>
                <th>Quantity</th>
                <th>Total</th>
                <th></th>
              </tr>
              </thead>
              <tr v-for="(item, index) in items">
                <td data-label="Lp.">{{ index + 1 }}</td>
                <td data-label="Item"><input type="text" v-model="item.description"/></td>
                <td data-label="Price/unite">
                  <div class="cell-with-input">{{ item.symbol }} <input type="number" min="0"
                                                                        v-model="item.price"/></div>
                </td>
                <td data-label="Quantity"><input type="number" min="0" v-model="item.quantity"/>
                </td>
                <td data-label="Total">100</td>
                <td class="text-right">
                  <v-btn icon color="error">
                    <v-icon>{{ icons.mdiClose }}</v-icon>
                  </v-btn>
                </td>
              </tr>
            </table>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary" block @click="addRow()">
              <v-icon small>
                {{ icons.mdiPlus }}
              </v-icon>
              Dodaj pozycję
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <h4 class="text-h5">
          Komentarz
        </h4>
      </v-col>
      <v-col cols="6">
        <h4 class="text-h5">
          Podsumowanie
        </h4>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import helpers from '@/utils/helpers'
import {
  mdiDotsVertical,
  mdiExportVariant,
  mdiPencilOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiClose,
  mdiEye,
  mdiPencil,
  mdiMagnify
} from '@mdi/js'

export default {
  name: 'Invoices',
  data() {
    return {
      files: [
        {
          color: 'blue',
          icon: 'mdi-clipboard-text',
          subtitle: 'Jan 20, 2014',
          title: 'Vacation itinerary',
        },
        {
          color: 'amber',
          icon: 'mdi-gesture-tap-button',
          subtitle: 'Jan 10, 2014',
          title: 'Kitchen remodel',
        },
      ],
      folders: [
        {
          subtitle: 'Jan 9, 2014',
          title: 'Photos',
        },
        {
          subtitle: 'Jan 17, 2014',
          title: 'Recipes',
        },
        {
          subtitle: 'Jan 28, 2014',
          title: 'Work',
        },
      ],
      invoice: {
        creationDate: new Date(),
        creationUser: 1,
        totalNet: 0,
        totalVAT: 0,
        totalGross: 0,
      },
      selection: 2,
      contractorDialog: false,
      gusLoading: false,
      nip: null,
      test: null,
      helpers,
      selectedContractor: {},
      menu1: null,
      menu2: null,
      menu3: null,
      hw: {},
      icons: {
        mdiMagnify,
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiPencilOutline,
        mdiExportVariant,
        mdiPlus,
        mdiEye,
        mdiClose,
        mdiPencil
      },
      items: [
        {description: "Website design", quantity: 1, price: 300}
      ],
      contractorsList: [
        {
          id: 1,
          contractorName: 'POL-BUD Sp. z o.o',
          NIP: '123-456-789'
        }
      ]
    }
  },
  computed: {
    total() {
      return this.items.reduce(
        (acc, item) => acc + item.price * item.quantity,
        0
      );
    }
  },
  methods: {
    addRow() {
      this.items.push({description: "", quantity: 1, price: 0});
    },
    downloadFromNIP() {
    },
    addNewContractor() {
      this.contractorDialog = true
    }, saveDialog() {
      this.contractorDialog = false
    },
    closeDialog() {
      this.contractorDialog = false
    }
  },
  filters: {
    currency(value) {
      return value.toFixed(2);
    }
  }
}
</script>

<style scoped>
.responsive-table {
  width: 100%;

@media only screen and (max-width: 760px) {

  table, thead, tbody, th, td, tr {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    padding: 2rem 0;
  }
}

}
</style>
